import { Col, Row, Typography } from 'antd';
import { PageProps } from 'gatsby';
import React from 'react';

import Container from '@/components/Container';
import Layout from '@/components/Layout';
import PhotoComposition from '@/components/PhotoComposition';
import SEO from '@/components/SEO';
import OrganizationSchema from '@/schema/OrganizationSchema';

// import * as styles from './partnership.module.css';

const mainText = `Мы предлагаем каждому каналу сбыта, индивидуально для него адаптированные условия поставки и гарантируем качество своей продукции. Ассортимент косметики по уходу за кожей лица и тела выводится на рынок в строгом соответствии ГОСТам и Техническому Регламенту Таможенного Союза.
«НПО Расепт» предлагает партнерам контрактное производство косметики с низкими ценами, сроком изготовления в 1 месяц, минимальной партией 5 000 шт. и с этикеткой Вашего бренда или рекламы.
Рецептурная база предприятия включает в себя более тысячи разработанных косметических продуктов, каждый из которых станет доработанным до требований заказчика с учётом запросов массового потребителя.
Ведь профессиональное понимание меняющихся потребностей и желаний покупателей разрешает нам действовать в интересах обеих сторон.
Антисептические и антибактериальные средства, коллагеновые гели для умывания, средства для снятия макияжа, средства по уходу за кожей лица, крем-мыло для интимной гигиены, детские шампуни, шампуни (направленного действия), антицеллюлитные продукты, прошли лабораторные и клинические исследования в испытательном центре, прошли лабораторные и клинические исследования в испытательном центре, что подтверждено протоколами испытаний, сертификатам и декларациям соответствия Таможенному союзу.
Находясь в условиях экономической нестабильности, мы с пониманием и ответственностью относимся к финансовым затратам партнеров и держим обещаниям по смете вложений, сохраняя достигнутые договорённости.
Скорость, гибкость и рациональность — вот отличительные качества работы компании «НПО Расепт».
Мы динамично развивающаяся российская компания с прочной опорой, которая позволяет нам поддерживать партнерские отношения в рабочем режиме.
В затруднительных для партнёра вопросах, касающихся размещения заказа или разработки новой продукции под своей торговой маркой, мы безвозмездно консультируем и прилагаем все усилия для снижения непредвиденных и накладных расходов контрагента.
Купить продукцию «НПО Расепт» оптом можно непосредственно у завода-изготовителя в г. Самара.
Мы открыты к диалогу о сотрудничестве и рады видеть Вас в числе наших партнеров.`;

const offers = [
  {
    title: 'Предложение дистрибьюторам',
    text: `Построение дистрибьюторской сети является для нас приоритетным направлением.
Совершенно понятно, что поддержание на складах всего ассортимента продукции и своевременная логистика этой продукции даже в самые дальние точки региона обслуживания под силу только умелому дистрибьютору. Однако так же неоценима работа, которую на себя берёт дистрибьютор в отношениях с покупателем, начиная от договорных обязательств и заканчивая финансовой дисциплиной. Сильная дистрибьюторская составляющая – это залог распланированной загрузки производственных мощностей производителя.
Мы готовы предоставить дистрибьюторам:
Чёткую логистику по всей России и странам СНГ;
Выверенную политику продаж;
Рассрочку платежа;
Органичный ассортимент;
Ввод в ассортимент новой/требуемой продукции;
Кросс-докинг.`,
  },
  {
    title: 'Предложение аптечным сетям',
    text: `Активное развитие аптечных сетей, расположение в шаговой доступности и непосредственное общение с покупателем выводят этот канал сбыта в одно из основных наших направлений распространения производимой нами продукции.
В работе с аптечными сетями в первую очередь мы ценим работу первостольников, так как именно они квалифицированно консультируют покупателей в том числе и по нашей продукции.
В свою очередь компания «НПО Расепт» готова предоставить аптечным сетям:
Бесплатную доставку нашей продукции в распределительный центр сети в любом городе России и стран СНГ;
Скидка за предоплату;
Программу ретро-бонусов;
Стимулирующие программы и акции для покупателей.`,
  },
  {
    title: 'Предложение контрактного производства',
    text: `Производство продукции под Собственной Торговой Маркой (СТМ)/(Private Labels) заказчика или иными словами контрактное производство — это ни что иное, как размещение заказа на выпуск у независимого производителя, под полной ответственностью которого будут гарантированно выполнены все производственно-технологические этапы выпуска продукции включая контроль качества входящего сырья и произведённой из него продукции.
Производственная Компания «НПО Расепт» принимает заказы на выпуск гелевых, кремовых и жидких форм косметической продукции и всегда выполняет взятые на себя обязательства перед партнёрами. Одно из наших преимуществ – это возможность фасовать продукцию в тару объёмом от 20 грамм. Производство осуществляется на новой полностью автоматической производственной линии, которая отвечает всем стандартам GMP и даёт возможность добиться самой низкой себестоимости выпускаемой продукции.`,
  },
  {
    title: 'Предложение торговым сетям',
    text: `Сотрудничество с торговыми сетями нам неоценимо важно в связи с широкой представленностью товаров разных марок и разной направленности в одном магазине.
Мы понимаем, что посещение современных торговых сетей/центров (магазинов у дома, супермаркетов, гипермаркетов и т.д.), становится определённой культурой семейных закупок и увлекательного шопинга. Не секрет, что уникальный, неповторимый стиль и ассортимент каждой торговой сети однозначно привлекает своего покупателя – это в свою очередь подвигает нас к предложению индивидуально выгодных условий сотрудничества для каждой отдельно взятой торговой сети.`,
  },
  {
    title: 'Совместные покупки',
    text: `Мы заботимся о том, чтобы наша продукция была доступной по цене и широко представлена в любом населённом пункте России. Поэтому мы предлагаем сотрудничество организаторам совместных покупок, как оперативным профессионалам в закупках по оптовым ценам для своих покупателей. Для получения условий сотрудничества, вышлите письмо на электронную почту: mail@rasept.com с указанием интернет площадки на которой вы работаете, вашу фамилию имя, номер телефона и город.`,
  },
];

const PartnershipPage: React.FC<PageProps> = ({ uri }) => {
  return (
    <Layout>
      <SEO
        title="Поставка антисептиков и косметики от производителя"
        description="Сотрудничество с профессионалами «НПО Расепт» — это прямые поставки косметики оптом от производителя со 100% гарантией качества, в любую точку России"
        uri={uri}
        image={null}
      />
      <OrganizationSchema />
      <Container>
        <Typography.Title className="text-center my-8" level={1}>
          Партнёрам
        </Typography.Title>
        <article className="prose prose-xl" style={{ maxWidth: '100%' }}>
          <Row>
            <Col flex="auto">
              <Typography.Paragraph className="text-center mt-2">
                {mainText}
              </Typography.Paragraph>
            </Col>
          </Row>
        </article>
        <PhotoComposition
          data={offers.map((i, idx) => {
            const partnershipPhotos = [
              '3735616.jpg',
              '207601.jpg',
              'IMG_0447.png',
              '1005638.jpg',
              '4483610.jpg',
            ];

            return {
              ...i,
              photoBase: partnershipPhotos[idx],
            };
          })}
        />
      </Container>
    </Layout>
  );
};

export default PartnershipPage;
